@import (reference) '~rev-less/global/variables';

.videoTile {
	background: transparent;
	border: none;
	box-shadow: none;

	:global(.tile-item) {
		background: transparent;
		border: none;

		a {
			:global(.tile-footer) {
				background: transparent;
				border: none;

				&::before {
					content: none;
				}
			}
		}
	}

}

.progressBarWrap {
	margin-top: 10px;
	background: @gray40;
	height: 3px;
	margin-right: 1%;
	width: 93%;

	@media all and (max-width: @viewPortMedWidth) {
		margin-top: 10px;
		margin-bottom: 10px;
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.progressBar {
	height: 100%;
}
