@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.tileItem {
	display: block;
	margin-bottom: 30px;
	background: @white;
	border: 1px solid @borderColor;
	.RoundedShape();
	position: relative;
	top: 0;

	&:hover {
		.TransitionAll(all linear 0.125s);
		border-color: @blackFade50;
		.BoxShadow(0 0 8px @blackFade50);

		a:hover {
			color: @accentColor;
			text-decoration: none;
		}
	}
}

.infoContainer {
	margin: 0 5px !important;
	h2 {
		margin: 0;
	}
}

.tileFooter {
	position: absolute;
	bottom: 0;
	width: ~'calc( 100% - 10px )';
	padding: 5px 0;
	background: @white;
	font-size: @type18;

	&, a {
		color: @color1LightText;
	}
}

.categoryTileFooter {
	composes: tileFooter;
	padding: 5px 0 5px 10px !important;
}

.icon {
	color: @color1LightText;
	font-size: @type18;
	margin-left: 5px;
}

.actionBtn {
	margin:10px 0;
	padding: 5px 10px;
}

.sidebarVbIcon {
	&:before {
		vertical-align: middle;
		font-size: @type20;
	}
}

.inactiveAnchor {
	&:hover {
		color: @color1LightText !important;
	}
}
