@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.txtArea {
	height: 140px;
	padding: 10px 16px;
	border-bottom: none;
	resize: none;
}

.actionWrapper {
	min-height: 40px;
	margin-top: -2px;
	padding: 5px;
	.displayFlex();
	.flexJustifyContent(flex-end);
}

.embedOptionWrapper {
	border-top-style: solid;
	border-top-width: 1px;
	border-color: inherit;
	.flex(1 1 100%);
	max-width: 100%;
	margin-top: 10px;
	padding: 10px 0;

	vb-toggle-button {
		width: 80px;
	}
}

.embedOptionCategory {
	.PointerCursor;
}

.btn {
	margin-left: 5px;
	border: none;
	outline: none !important;
	box-shadow: none !important;
	background: none !important;
	color: @white !important;

	.light & {
		color: @black !important;
	}
}

.toolbarBtn {
	.btn;
	composes: btn from global;
	padding: 0;

	&:hover {
		text-decoration: underline;
	}
}

.sparkLogo {
	display: inline-block;
	position: relative;
	top: 4px;
	height: 20px;
	width: 20px;
	background-image: url(/img/webex-logo-new.png);
	background-size: contain;
	background-repeat: no-repeat;
}

.phoneLogo {
	top: 1px;
	font-size: @type18;
}

.label {
	.alignSelf(center);
	margin-right: 15px;
	width: 10%;
}

.input {
	width: 30%
}

.sparkShare {
	:global(.modal-content) {
		overflow: visible;
	}
}

.startAt {
	margin-left: auto !important;
	flex: 0 0 40%;
}

.playerSize {
	width: 40%;
}

.playerDimensions {
    width: 60%;
    padding-left: 5px;

	>input, >label {
		width: 45%;
		margin: 0;
	}
}
