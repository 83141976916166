@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

media-container {
	display: block;
}

.media-list-results {
	overflow-x: hidden;
	min-height: 80vh;

	.right-sidebar {
		z-index: 9;
		border-left: 1px solid #161616;
		background-color: inherit;

		&, &.is-open {
			height: auto;
		}
	}
}

.media-layout {
	position: relative;
}

.media-layout-content {
	position: relative;
	min-height: 90vh;
	width: 100%;
	margin-top: 16px;
}

@media (min-width: @viewPortMedWidth) {
	.media-layout-content {
		padding-bottom: 63px;
	}
}

@media (max-width: (@viewPortMedWidth - 1)) {
	.media-list-results {
		.right-sidebar {
			z-index: 1035;
		}
	}
}
