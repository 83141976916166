@import "~rev-less/global/variables";

.bulk-edit {
	.help-block {
		color: @white;
	}

	video-expiration {
		.col-lg-9 {
			width: 100%;
		}
	}

	tags-input {
		color: @black
	}

	.sidebar-form {
		margin: 0 -16px;
		padding: 18px 16px;
	}
}

.bulk-edit-sidebar {
	.control-label {
		color: @white;
	}

	@media all and (min-width: @viewPortMedWidth) {

		.bulk-edit-settings {
			border-top: 1px solid @white;
			margin-top: @type8;
		}

		.bulk-edit-cancel {
			border-bottom: 1px solid @white;
		}
	}
}

.bulk-edit-tags {
	background-color: @white;
}

.bulk-edit-label {
	color: @white;
}
