@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.root {
	.title {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		margin: 9px 0 9px 0;
	}

	.thumbnailWrapper {
		@media all and (max-width: (@viewPortMedWidth - 1px)) {
			height: 45px;
			flex-shrink: 0;
			margin-right: 10px;
			width: 75px;
		}
	}

	.videoOverlay {
		.AbsoluteFill;

		.bottomOverlay {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			flex-direction: column;
			z-index: 1;

			.progressBarWrap {
				bottom: 0;
				left: 0;
				right: 0;
				background-color: @blackFade40;

				.progressBar {
					height: 4px;
				}
			}

			.videoDurationOverlay {
				position: relative;
				top: unset;
				height: 19px;
				line-height: 2em;
				padding: 0px 10px;
				width: 45px;
				bottom:0;
				right: 0;
			}

			.liveBadge {
				position: relative;
				margin: 4px;
				top: unset;
				right: unset;
			}

			video-360-indicator {
				position: relative;
				top: unset;
				right: unset;
			}
		}
	}

	.videoInfo {
		display: grid;
		margin-left: 8px;
		color: var(--theme-primary-txt-fade-50);

		span {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			line-height: 1.1em;
			font-size: @type13;
		}
	}

	.tileFooter {
		display: flex;
		padding: 0;

		vb-profile-picture {
			span {
				height: 35px;
				width: 35px;
				border-radius: 50%;

				img {
					height: 35px;
					width: 35px;
					border-radius: 50%;
				}

				:global(.user) {
					padding: 0 8px;
					height: unset;
				}
			}
			@media all and (max-width: (@viewPortMedWidth - 1px)) {
				display: none;
			}
		}

		.ownerName {
			padding-top: 2px;

			@media all and (max-width: (@viewPortMedWidth - 1px)) {
				display: none;
			}
		}
	}

	.tileItem {
		background: transparent !important;
		@media all and (max-width: (@viewPortMedWidth - 1px)) {
			height: 45px;
			margin-bottom: 16px;
		}
	}

	.imageContainer {
		@media all and (max-width: (@viewPortMedWidth - 1px)) {
			display: flex;
		}
	}
}
