@import (reference) '~rev-less/global/mixins';
@import (reference) '~rev-less/global/variables';

.mediaToolbar {
	display: block;
	position: fixed;
	min-height: 48px;
	width: 100%;
	z-index: 10;
	background: @backgroundLighten03;
	border-bottom: 1px solid @borderColorLight;
}

.mediaToolbarContainer {
	display: flex;
	align-items: stretch;
	padding: 10px 0 4px;
	flex-direction: column;

	@media (min-width: @viewPortSmallWidth) {
		flex-direction: row;
	}
}

.mediaToolbarNav {
	margin-right: 10px;
	flex-grow: 1;
	font-size: @type20;
	line-height: 2rem;

	:global(.chevron-right) {
		font-size: @type16;
	}

	@media (min-width: @viewPortSmallWidth) {
		align-self: flex-end;
	}
}

.mediaToolbarControls {
	flex-grow: 0;
	flex-shrink: 0;
	text-align: right;
}

.btn,
.btnGroup :global(.btn) {
	height: 36px;
	padding: 0 @typeUnit3-4x !important;
	.RoundedShape(2px);
	background: none;
	border-color: @borderColor;
	color: @color1MedText;
	.BoxShadow(inset 0 1px 0 @white);
	font-size: @type14;
	text-shadow: none;
	line-height: 36px;

	&:global(.active) {
		&, &:hover {
			&:extend(.BtnWhiteActive);
		}
	}

	&:hover:not(:global(.disabled)) {
		border: 1px solid @color1Lighten50;
	}

	@media (max-width: (@viewPortSmallWidth - 1px)) {
		margin-top: 5px;
		margin-bottom: 5px;
	}
}

.mediaToolbarControlBtns {
	display: flex;
	padding-top: 10px;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;

	&:empty {
		padding-top: 0;
	}

	:global(.badge) {
		background-color: @accentColor;
		left: 4px;
	}

	:global(.btn-group) {
		line-height: 36px;
	}

	@media (min-width: @viewPortSmallWidth) {
		align-items: center;
		justify-content: flex-end;
	}
}

.playIcon {
	.RoundedShape(20px);
	border: 3px solid @white;
	background: @white;
	color: @accentColor !important;
	margin-right: 10px;
}

.teamHeader {
	display: block;
	border-top: 1px solid @color2;
	padding: 1rem 0 1rem 2rem;
	height: 125px;
	min-width: 100%;
	z-index: 15;
	background-color: @backgroundLighten03;

	@media all and (max-width: @viewPortMedWidth) {
		position: relative;
		top: 0;
	}
}

.uploadToTeam {
	height: inherit;
	margin-top: -17px;
	width: 8rem;
	float: right;
	background: @blackFade50;
	padding: 0;
	position: relative;

	:global(.upload-btn) {
		height: inherit;
		width: inherit;

		background: none;
		border-color: @borderColor;
		color: @color1MedText;
		.BoxShadow(inset 0 1px 0 @white);
		font-size: @type14;
		text-shadow: none;
		padding: 0 @typeUnit3-4x !important;
		line-height: 36px;
	}

	:global(.disk_open) {
		color: @white;
		font-size: 2rem;
	}

	:global(.upload-text) {
		font-size: .7rem;
		color: @white;
	}
}

.teamLogoContainer {
	max-width: 120px;

	@media (max-width: @viewPortSmallWidth) {
		padding-left: 0 !important;
	}
}

.teamHeaderContent {
	@media (max-width: @viewPortMobileDeviceWidth) {
		padding: 0 !important;
	}
}

.teamName {
	line-height: 1.2;
	margin: 0 0 .5% 0;
	word-break: break-all;
}

.verticalDivider {
	border-left: 2px solid;
	margin-left: 5px;
	padding-left: 7px;
}
